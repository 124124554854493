.related-questions {
  background: url(/assets/phy/icons/icon-search-concepts.png);
}

.related-concepts {
  background: url(/assets/phy/icons/icon-books.png);
}

.related-title {
  height: 65px;
  padding-left: 75px;
  padding-top: 23px;
  background-repeat: no-repeat;
  &:after {
    clear:both;
  }
}
