@import "../common/footer";

.footer-links {
  a {
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
}

.footer-link:focus {
  box-shadow: 0 0 0 2pt white;
  border-radius: 3px;
  outline: none !important;
}

.footer-top a:focus {
  outline: 0.2rem solid white;
}

.footer-logo {
  margin-top: -0.55px;
}

.footer-org-logo {
  max-width: 12rem;
}

.footer-top {
  background-color: #333333;
}

.footer-link {
  @extend .py-2;
}
