// ISAAC

.accordion-part {
  display: none;
  .question-panel & {
    display: inline;
  }
}

.stage-label {
  font-family: $secondary-font;
  min-width: 130px;
  max-width: 130px;
  text-align: center;

  @include media-breakpoint-down(sm) {
    min-width: 105px;
    max-width: 105px;
  }
}

.dynamic-x-padding .pad {
  &:first-of-type {
      padding-left: 0;
      padding-right: 8px;
  }

  &:last-of-type {
      padding-left: 8px;
      padding-right: 0;
  }

  &:only-child {
    padding: 0;
  }

  padding: 0 4px;

  @media (max-width: 991px) {
      padding-left: 0px;
      padding-right: 0px;
  }
}

.accordion-icon {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
  width: 25px;
  height: 20px;
  margin-right: 0.25rem;
  &-tick {
    background-image: url(/assets/common/icons/tick.svg);
  }
  &-cross {
    background-image: url(/assets/common/icons/untick.svg);
  }
  &-report {
    background-image: url(/assets/common/icons/flag-accordion.svg);
    background-color: transparent;
    opacity: 0.4;
    align-self: flex-end;
    margin-bottom: 1.5rem;
    margin-right: 1rem;
    &:hover {
      opacity: 1;
    }
    &:focus-visible {
      opacity: 1;
    }
  }
}

// Nomensa accordions.scss
.accordion {
  &:first-of-type {
    border-top: 0;
  }
  &:last-of-type {
    //border-bottom: 1px solid $gray-120;
    margin-bottom: 1rem;
  }
  .accordion-header {
    //border-color: $gray-120;
    //border-style: solid;
    //border-width: 0 1px;
    button {
      border-radius: 0;
      font-family: $secondary-font-semi-bold;
      font-size: 1.25rem !important;
      transition: all 0.5s ease;
      position: relative;
      display: flex;
      flex-direction: row;
      align-items: center;
      border: none;
      text-align: left;
      text-decoration: none;

      .accordion-title {
        flex-grow: 1;

        h2 {
          font-size: inherit;
          font-family: inherit;
          font-weight: inherit;
          line-height: inherit;
          margin-bottom: inherit;
        }
      }

      &:after {
        content: "";
        background-image: url('/assets/common/icons/chevron_down.svg');
        background-repeat: no-repeat;
        background-position: right;
        width: 30px;
        height: 10px;
        margin-right: 1rem;
        margin-top: auto;
        margin-bottom: auto;
      }

      &.active {
        border-bottom: 1px solid $shadow-08 !important;
        &:after {
          background-image: url('/assets/common/icons/chevron_up.svg');
        }
      }

      &.de-emphasised {
        background: darken(#fff, 9%);
        color: lighten(black, 10%);
        .stage-label {
          background: lighten($dark, 10%);
        }
      }

      &:hover {
        .accordion-title {
          text-decoration: underline;
        }
      }
    }
  }

  .card {
    background: transparent;
    border-width: 0;
  }
}
