// NOMENSA logo.scss
.header-logo {
  float: left;

  @include respond-above(sm) {
    float: none;
  }

  img {
    width: 59px;
    height: 87px;
    vertical-align: top;

    @include respond-above(sm) {
      width: 290px;
      height: 136px;
    }

    @media print {
      width: 290px;
      height: 136px;
    }
  }
}
