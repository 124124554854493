@import "../common/header";

// reverse final element in the navigation bar to keep options on the screen
ul#main-menu {
  li.dropdown.nav-item:last-child {
    div.dropdown-menu {
      right: 0;
      left: auto;
      &::before {
        right: 1rem;
        left: auto;
      }
    }
  }
}

// NOMENSA header.scss
// !important used to override bootstraps use of !important :(

header {
  background-size: 100%;
  background-repeat: no-repeat;
  position: relative;

  > .container {
    @include respond-below(sm) {
      max-width: 100% !important;
    }
  }

  .header-bar {
    position: relative;
    @include clearfix;

    @include respond-above(sm) {
      padding-right: auto;
    }
  }

  .header-links {
    justify-content: flex-end !important;

    @include respond-below(xs) {
      padding-right: 20% !important;
    }
    @include respond-above(xs){
      padding-right: 28% !important;
    }
    @include respond-above(sm) {
      margin-top: 0.2rem;
      padding-right: 0 !important;
      width: 100%;
    }
  }
}

a.skip-main:focus, a.skip-main:active {
  left: 7%;
  top: 70px;
}
div#header-progress {
  max-width: min-content;
}
header {
  z-index: 20;
  background-image: url('/assets/phy/line.svg');
  @include respond-above(sm) {
    background-size: 100% 70%;
  }

  .header-links {
    @include respond-below(md) {
      margin-top: 1.7rem !important;
    }
  }
}
