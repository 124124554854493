@use 'sass:math';

.card-neat {
  .event-card-image {
    position: relative;
    min-height: 100px;

    .event-card-image-banner {
      position: absolute;
      bottom: 0;
      background-color: rgba(51,51,51,0.73);
      width: 100%;
      color: white;
      text-align: right;
      padding: 8px 15px 8px;

      &.disabled {
        z-index: 5;
      }
    }

    img {
      width: 100%;
      height: 100%;
    }
  }

  .event-card-icons {
    margin-left: auto;
    img {
      height: 2rem;
      width: 2rem;
      margin-left: 0.25rem;
    }
  }
}

.card-deck .news-card {
  max-width: 308px;
  margin-bottom: 1.5rem;

  a img {
    @include aspect-ratio(5, 3);
    object-fit: cover;
  }
}

// number of news cards per line for each breakpoint
$news-cards-per-line: (
  xs: 2,
  lg: 3
);

@each $name, $breakpoint in $grid-breakpoints {
  @if (map-has-key($news-cards-per-line, $name)) {
    @include media-breakpoint-up($name) {
      .card-deck .news-card {
        flex: 0 0 calc(#{math.percentage(math.div(1, map-get($news-cards-per-line, $name)))} - #{$grid-gutter-width});
        width: 100%;
      }
    }
  }
}
