#graph-sketcher-modal,
#graph-sketcher-modal canvas {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #ffffff;
    z-index: 250;
}

#graph-sketcher-modal {
    min-height: 310px;
    min-width: 310px;
    .graph-sketcher-ui {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 255;

        code#graph-sketcher-ui-debug-window {
            position: absolute;
            padding: 10px;
            background-color: rgba(255, 255, 255, 0.8);
            touch-action: none;
            max-width: max(50%, 400px);
            #copy-link {
                margin-top: 10px;
                cursor: pointer;
                pointer-events: all;
                &:hover {
                    text-decoration: underline;
                }
            }
            div {
                max-height: 400px;
                overflow-y: auto;
            }
            pre {
                white-space: pre-wrap;       /* css-3 */
                white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
                white-space: -o-pre-wrap;    /* Opera 7 */
                word-wrap: break-word;       /* Internet Explorer 5.5+ */
            }
            #graph-sketcher-ui-show-slop, label {
                pointer-events: all;
                touch-action: all;
                cursor: pointer;
            }
        }

        .button {
            display: block;
            position: absolute;
            cursor: pointer;
            background-color: transparent;
            background-size: contain;
            background-repeat: no-repeat;
            background-position-y: center;
            text-indent: -100vw;
            overflow: hidden;

            &.visible {
                visibility: visible;
            }

            &.hidden {
                visibility: hidden;
            }

            &#graph-sketcher-ui-trash-button {
                opacity: 1;
                &:disabled {
                    opacity: 0.4;
                    cursor: not-allowed !important;
                }
                background-image: url('/assets/phy/icons/inequality-trash-phy.svg');
            }

            &#graph-sketcher-ui-reset-button {
                opacity: 1;
                &:disabled {
                    opacity: 0.4;
                    cursor: not-allowed !important;
                }
                background-image: url('/assets/phy/icons/inequality-reset-phy.svg');
            }

            &#graph-sketcher-ui-undo-button {
                background-image: url('/assets/phy/icons/hex-undo.svg');

                &:hover {
                    background-image: url('/assets/phy/icons/hex-undo-active.svg');
                }
            }

            &#graph-sketcher-ui-redo-button {
                background-image: url('/assets/phy/icons/hex-redo.svg');

                &:hover {
                    background-image: url('/assets/phy/icons/hex-redo-active.svg');
                }
            }

            &#graph-sketcher-ui-submit-button {
                background-image: url('/assets/phy/icons/inequality-confirm-phy.svg');
            }

            &#graph-sketcher-ui-help-button {
                background-image: url('/assets/phy/icons/inequality-help-phy.svg');
            }

            &#graph-sketcher-ui-question-button {
                background-image: url('/assets/phy/icons/inequality-togglequestion-phy.svg');
            }

            &#graph-sketcher-ui-debug-button {
                background-image: url('/assets/phy/icons/hex-debug.svg');

                &:hover {
                    background-image: url('/assets/phy/icons/hex-debug-active.svg');
                }
            }

            &#graph-sketcher-ui-bezier-button {
                background-image: url('/assets/phy/icons/graph-sketcher-polynomial.svg');

                &:hover,
                &.active {
                    background-image: url('/assets/phy/icons/graph-sketcher-polynomial-active.svg');
                }
            }

            &#graph-sketcher-ui-linear-button {
                background-image: url('/assets/phy/icons/graph-sketcher-straight.svg');

                &:hover,
                &.active {
                    background-image: url('/assets/phy/icons/graph-sketcher-straight-active.svg');
                }
            }
        }

        div#graph-sketcher-ui-color-select-hexagons {
            position: absolute;
            .hover-text {
                white-space: nowrap;
                text-align: center;
                display: none;
            }
            &:hover .hover-text {
                display: block;
            }
            svg {
                #blue-hex-colour {
                    fill: rgb(93,165,218);
                    &.active {
                        stroke: darken(rgb(93,165,218), 15%);
                        stroke-width: 4px;
                    }
                }
                #orange-hex-colour {
                    fill: rgb(250,164,58);
                    &.active {
                        stroke: darken(rgb(250, 164, 58), 15%);
                        stroke-width: 4px;
                    }
                }
                #green-hex-colour {
                    fill: rgb(96,189,104);
                    &.active {
                        stroke: darken(rgb(96,189,104), 15%);
                        stroke-width: 4px;
                    }
                }
            }
        }

        @include respond-above(lg) {
            $button-size: 90px;
            $button-spacing: 10px;

            #graph-sketcher-ui-debug-window {
                top: 2 * $button-spacing;
                left: 3 * $button-spacing + $button-size;
            }

            .button {
                width: $button-size;
                height: $button-size;

                &#graph-sketcher-ui-trash-button {
                    top: 2 * $button-spacing;
                    right: 2 * $button-spacing;
                }

                &#graph-sketcher-ui-reset-button {
                    top: 3 * $button-spacing + $button-size;
                    right: 2 * $button-spacing;
                }

                &#graph-sketcher-ui-question-button {
                    bottom: 3 * $button-spacing + $button-size;
                    left: 2 * $button-spacing;
                }

                &#graph-sketcher-ui-undo-button {
                    bottom: 2 * $button-spacing;
                    left: 2 * $button-spacing;
                }

                &#graph-sketcher-ui-redo-button {
                    bottom: 2 * $button-spacing;
                    left: 3 * $button-spacing + $button-size;
                }

                &#graph-sketcher-ui-submit-button {
                    bottom: 2 * $button-spacing;
                    right: 2 * $button-spacing;
                }

                &#graph-sketcher-ui-help-button {
                    bottom: 3 * $button-spacing + $button-size;
                    right: 2 * $button-spacing;
                }

                &#graph-sketcher-ui-debug-button {
                    top: 2 * $button-spacing;
                    left: 2 * $button-spacing;
                }

                &#graph-sketcher-ui-bezier-button {
                    top: 2 * $button-spacing;
                    right: 4 * $button-spacing + 2 * $button-size;
                }

                &#graph-sketcher-ui-linear-button {
                    top: 2 * $button-spacing;
                    right: 5 * $button-spacing + 3 * $button-size;
                }
            }

            div#graph-sketcher-ui-color-select-hexagons {
                top: 2 * $button-spacing;
                right: 3 * $button-spacing + $button-size;
                width: $button-size;
                height: $button-size;
            }
        }

        @media (orientation:portrait) {
            @include respond-below(xs) {
                $button-size: 61px;
                $button-spacing: 8px;

                #graph-sketcher-ui-debug-window {
                    display: none;
                    top: 2 * $button-spacing;
                    left: 3 * $button-spacing + $button-size;
                }

                .button {
                    width: $button-size;
                    height: $button-size;

                    &#graph-sketcher-ui-trash-button {
                        top: 2 * $button-spacing;
                        right: 2 * $button-spacing;
                    }

                    &#graph-sketcher-ui-reset-button {
                        top: 3 * $button-spacing + $button-size;
                        right: 2 * $button-spacing;
                    }

                    &#graph-sketcher-ui-question-button {
                        bottom: 3 * $button-spacing + $button-size;
                        left: 2 * $button-spacing;
                    }

                    &#graph-sketcher-ui-undo-button {
                        bottom: 2 * $button-spacing;
                        left: 2 * $button-spacing;
                    }

                    &#graph-sketcher-ui-redo-button {
                        bottom: 2 * $button-spacing;
                        left: 3 * $button-spacing + $button-size;
                    }

                    &#graph-sketcher-ui-submit-button {
                        bottom: 2 * $button-spacing;
                        right: 2 * $button-spacing;
                    }

                    &#graph-sketcher-ui-help-button {
                        bottom: 3 * $button-spacing + $button-size;
                        right: 2 * $button-spacing;
                    }

                    &#graph-sketcher-ui-debug-button {
                        display: none;
                        top: 3 * $button-spacing + $button-size;
                        left: 2 * $button-spacing;
                    }

                    &#graph-sketcher-ui-bezier-button {
                        top: 2 * $button-spacing;
                        left: 3 * $button-spacing + $button-size;
                    }

                    &#graph-sketcher-ui-linear-button {
                        top: 2 * $button-spacing;
                        left: 2 * $button-spacing;
                    }
                }

                div#graph-sketcher-ui-color-select-hexagons {
                    top: 2 * $button-spacing;
                    left: 4 * $button-spacing + 2 * $button-size;
                    width: $button-size;
                    height: $button-size;
                    svg {
                        width: $button-size;
                        height: $button-size;                        
                    }
                }
            }
        }

        @include respond-above(xs) {
            $button-size: 90px;
            $button-spacing: 10px;

            #graph-sketcher-ui-debug-window {
                top: 2 * $button-spacing;
                left: 3 * $button-spacing + $button-size;
            }

            .button {
                width: $button-size;
                height: $button-size;

                &#graph-sketcher-ui-trash-button {
                    top: 2 * $button-spacing;
                    right: 2 * $button-spacing;
                }

                &#graph-sketcher-ui-reset-button {
                    top: 3 * $button-spacing + $button-size;
                    right: 2 * $button-spacing;
                }

                &#graph-sketcher-ui-question-button {
                    bottom: 3 * $button-spacing + $button-size;
                    left: 2 * $button-spacing;
                }

                &#graph-sketcher-ui-undo-button {
                    bottom: 2 * $button-spacing;
                    left: 2 * $button-spacing;
                }

                &#graph-sketcher-ui-redo-button {
                    bottom: 2 * $button-spacing;
                    left: 3 * $button-spacing + $button-size;
                }

                &#graph-sketcher-ui-submit-button {
                    bottom: 2 * $button-spacing;
                    right: 2 * $button-spacing;
                }

                &#graph-sketcher-ui-help-button {
                    bottom: 3 * $button-spacing + $button-size;
                    right: 2 * $button-spacing;
                }

                &#graph-sketcher-ui-debug-button {
                    top: 2 * $button-spacing;
                    left: unset;
                    right: 3 * $button-spacing + $button-size;
                }

                &#graph-sketcher-ui-bezier-button {
                    top: 3 * $button-spacing + $button-size;
                    left: 2 * $button-spacing;
                }

                &#graph-sketcher-ui-linear-button {
                    top: 2 * $button-spacing;
                    left: 2 * $button-spacing;
                }
            }

            div#graph-sketcher-ui-color-select-hexagons {
                top: 4 * $button-spacing + 2 * $button-size;
                left: 2 * $button-spacing;
                width: $button-size;
                height: $button-size;
                svg {
                    width: $button-size;
                    height: $button-size;                        
                }
            }
        }

        @media screen and (orientation:landscape) and (max-height: 575px) {
            $button-size: 61px;
            $button-spacing: 8px;

            #graph-sketcher-ui-debug-window {
                display: none;
                top: 2 * $button-spacing;
                left: 3 * $button-spacing + $button-size;
            }

            .button {
                width: $button-size;
                height: $button-size;

                &#graph-sketcher-ui-trash-button {
                    top: 2 * $button-spacing;
                    right: 2 * $button-spacing;
                }

                &#graph-sketcher-ui-reset-button {
                    top: 3 * $button-spacing + $button-size;
                    right: 2 * $button-spacing;
                }

                &#graph-sketcher-ui-question-button {
                    bottom: 3 * $button-spacing + $button-size;
                    left: 2 * $button-spacing;
                }

                &#graph-sketcher-ui-undo-button {
                    bottom: 2 * $button-spacing;
                    left: 2 * $button-spacing;
                }

                &#graph-sketcher-ui-redo-button {
                    bottom: 2 * $button-spacing;
                    left: 3 * $button-spacing + $button-size;
                }

                &#graph-sketcher-ui-submit-button {
                    bottom: 2 * $button-spacing;
                    right: 2 * $button-spacing;
                }

                &#graph-sketcher-ui-help-button {
                    bottom: 3 * $button-spacing + $button-size;
                    right: 2 * $button-spacing;
                }

                &#graph-sketcher-ui-debug-button {
                    display: none;
                    top: unset;
                    bottom: 3 * $button-spacing + $button-size;
                    left: 2 * $button-spacing;
                }

                &#graph-sketcher-ui-bezier-button {
                    top: 3 * $button-spacing + $button-size;
                    left: 2 * $button-spacing;
                }

                &#graph-sketcher-ui-linear-button {
                    top: 2 * $button-spacing;
                    left: 2 * $button-spacing;
                }
            }

            div#graph-sketcher-ui-color-select-hexagons {
                top: 2 * $button-spacing;
                left: 3 * $button-spacing + $button-size;
                width: $button-size;
                height: $button-size;
                svg {
                    width: $button-size;
                    height: $button-size;                        
                }
            }
        }

    }
}

.graph-sketcher-preview {
    padding: 1em 0;
    text-align: center;
}