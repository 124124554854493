@import "../common/gameboard";

.modal-body {
  .basic-multi-select {
    .select__control.select__control--is-focused {
      border-color: $primary;
      box-shadow: 0 0 0 0.2rem $shadow-08;
    }

    .select__menu {
      z-index: 2;
      .select__option.select__option--is-focused {
        background-color: $primary;
      }
    }
  }
}

.gameboard-builder-banner {
  position: relative;
  text-align: center;
}

.gameboard-builder-undo {
  position: absolute;
  left: 0;
}

.gameboard-builder-refresh {
  position: absolute;
  right: 0;
}

.responsive {
  max-height: 60vh;
  .table.table-bordered {
    thead {
      @include table-sticky();
    }
    tr {
      &.disabled {
        background-color: lightgrey;
      }

      &.selected {
        background-color: lightyellow;
      }
    }
  }
}
