@import "../common/forms";

.basic-multi-select {
  .select__control.select__control--is-focused {
    box-shadow: 0 0 0 0.2rem $focus-blue;
  }
  .select__control, .select__control:hover {
    border-color: $black;

    .select__value-container {
      padding: 0 0 0 1.2em;
    }
  }

  .select__menu {
    z-index: 2;
    .select__option.select__option--is-focused {
      background-color: $primary;
    }
  }
}

.form-required {
  &:after {
    content: '*';
    padding-left: 6px;
    font-weight: 600;
    color: $secondary;
  }
}
