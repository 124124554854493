@import "../common/progress-bar";

.progress-bar-outer {
  background: $gray-110;

  .progress-bar-inner, .progress-bar-secondary {
    background: $secondary;
  }
  .bar-alevel {
    background: $phy_physics !important;
  }
  .bar-gcse {
    background: $a11y_green !important;
  }
  .bar-maths {
    background: $phy_maths !important;
  }
  .bar-chemistry {
    background: $phy_chemistry !important;
  }
  .bar-biology {
    background: $phy_biology !important;
  }
}
