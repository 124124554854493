@import "../common/featured-profile";

.profile-description {
  padding: 0.5rem 1.5rem 0 1.5rem;
}

// Detecting a div with any number of featured-profile children
div:has(> .featured-profile) {
  @extend .flex-row;
  @extend .card-deck;
  @extend .row-cols-1;
  @extend .row-cols-sm-2;
  @extend .row-cols-md-3;

  .featured-profile {
    margin-bottom: 1rem;
  }
}

// Detecting a div with exactly two featured-profile children (co-founders)
div:has(> .featured-profile:first-child:nth-last-child(2)) {
  @extend .flex-row;
  @extend .row-cols-1;
  @extend .row-cols-sm-2;

  .featured-profile {
    @extend .isaac-tab;

    width: 47.5%;
    @include media-breakpoint-down(sm) {
      width: 100%;
    }

    margin-top: 0%;
    margin-right: 0%;
    padding: 2.5%;
    overflow-x: hidden;

    .profile-description {
      padding: 1rem 0;
      font-size: initial;
      color: black;
    }

    .profile-image {
      padding-bottom: 1rem;
      width: 95%;
    }
  }

  .featured-profile:first-child {
    margin-right: 5%;
  }
}

@include media-breakpoint-down(lg) {
  div.pad:has(> div > .featured-profile:first-child:nth-last-child(2)) {
    padding-left: 0;
  }
}

