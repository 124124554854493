@import "../common/assignment-progress";

.assignment-progress-gameboard .gameboard-header > *:first-child:before {
  background: $secondary;
  color: white;
}

.assignment-progress-group .group-name {
  background: $secondary;
  color: white;
}

.assignment-progress-gameboard .gameboard-header:not(.text-muted) > *:first-child:before {
  background: $primary !important;
}

.assignment-count-pill {
  @include pill-tag(white, darken($phy_green, 20));
  min-width: 25px;
  text-align: center;
}
