.physics-strapline {
  //font-family: 'exo_2regular', sans-serif;
  //font-size: 1.8rem;
  h1 {
  }
  small {
    font-family: "exo_2regular", sans-serif;
    font-weight: 400;
    line-height: 1;
  }
}

.physics-site-intro {
  @include respond-above(sm) {
    font-size: large;
  }
}
