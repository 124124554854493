@-webkit-keyframes gutter-overflow {
  0%  {width: 100%; @include make-col-offset(0, 8);}
  60%  {width: 100%; @include make-col-offset(0, 8);}
  100% {width: 110%; @include make-col-offset(-1, 20);}
}
@-moz-keyframes gutter-overflow {
  0%  {width: 100%; @include make-col-offset(0, 8);}
  60%  {width: 100%; @include make-col-offset(0, 8);}
  100% {width: 110%; @include make-col-offset(-1, 20);}
}
@-o-keyframes gutter-overflow {
  0%  {width: 100%; @include make-col-offset(0, 8);}
  60%  {width: 100%; @include make-col-offset(0, 8);}
  100% {width: 110%; @include make-col-offset(-1, 20);}
}
@keyframes gutter-overflow {
  0%  {width: 100%; @include make-col-offset(0, 8);}
  60%  {width: 100%; @include make-col-offset(0, 8);}
  100% {width: 110%; @include make-col-offset(-1, 20);}
}

@include media-breakpoint-up(md) {
    .accordion .expansion-layout {
      -webkit-animation: gutter-overflow 0.5s; /* Safari 4+ */
      -moz-animation:    gutter-overflow 0.5s; /* Fx 5+ */
      -o-animation:      gutter-overflow 0.5s; /* Opera 12+ */
      animation:         gutter-overflow 0.5s; /* IE 10+, Fx 29+ */
      @include make-col-offset(-1, 20);
      width: 110%;
    }
}
  
