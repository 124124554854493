@import "../common/my-account";

.revoke-teacher {
  color: #669933;
  font-weight: 600;
}
.reconnect-teacher {
  color: #999;
  font-weight: 600;

  &:hover {
    color: #666;
  }
}

.linked-account-button-outer {
  > label, > button {
    width: max-content;
  }
}

.connect-list {
  .connect-list-header {
    .icon-person-active {
      margin-top: 7px;
    }
  
    h4 {
      padding: 0rem 1rem;
    }
  }
}

.teachers-connected {
  .icon-person,
  .icon-person-active {
    margin-top: 2px;
  }

  .connections-fixed-length-container {
    max-width: calc(100% - 91.8px);
  }
}
