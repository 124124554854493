@import "../common/inequality-modal.scss";

#inequality-modal {
    .inequality-ui {
        &.button {
            &.confirm {
                background-image: url('/assets/phy/icons/inequality-confirm-phy.svg');
            }
            &.centre {
                background-image: url('/assets/phy/icons/inequality-centre-phy.svg');
            }
            &.help {
                background-image: url('/assets/phy/icons/inequality-help-phy.svg');
            }
            &.show-question {
                background-image: url('/assets/phy/icons/inequality-togglequestion-phy.svg');
            }
            &.trash {
                background-image: url('/assets/phy/icons/inequality-trash-phy.svg');
            }
            &.reset {
                background-image: url('/assets/phy/icons/inequality-reset-phy.svg');
            }
        }
        .clear-number {
            background-image: url('/assets/phy/icons/inequality-clear-phy.svg');
        }

        &.katex-preview {
            border: 1px solid $phy_green;
        }

        &.menu-bar {
            background: $phy_green;

            div.top-menu {
                &.numbers {
                    & > div {
                        &.input-box {
                            .menu-item {
                                svg.v-hexagon {
                                    stroke: darken($phy_green, 10%);
                                }
                            }
                        }

                        &.keypad-box {
                            .key {
                                svg.v-hexagon {
                                    stroke: darken($phy_green, 10%);
                                }
                            }
                        }
                    }
                }

                ul {
                    background: darken($phy_green, 20%);

                    li {
                        &.active {
                            background: $phy_green;
                        }

                        svg.v-hexagon {
                            stroke: darken($phy_green, 10%);
                        }
                    }

                    &.sub-menu {
                        background: $phy_green;
                    }
                }

                .sub-menu-tabs {
                    li {
                        &.inactive {
                            svg.v-hexagon {
                                fill: $phy_green;
                            }
                        }
                    }
                }
            }
        }

        .menu-tabs {
            ul {
                li {
                    &.active {
                        color: white;
                        background: $phy_green;

                        svg.tab-triangle {
                            fill: $phy_green;
                        }
                    }
                }
            }
        }
    }
}

#moving-menu-item {
    svg.v-hexagon {
        stroke: darken($phy_green, 10%);
    }
}
