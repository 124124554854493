@import "../common/button";

.btn, input.btn {
  border-radius: 0.25rem;
  font-family: $secondary-font-bold;
  font-size: 1.125rem;
  letter-spacing: 0.5px;
  min-width: 136px;
  padding: 0.5rem 2.1rem;
  white-space: normal;

  &.btn-negative, &.btn-neutral, &.btn-positive, &.btn-negative-answer, &.btn-neutral-answer, &.btn-positive-answer {
    border: 1px solid transparent;
    color: $black;
    min-width: 100px !important;
    padding: 0 !important;
    border-radius: 5px;

    &.focus,
    &:focus,
    &:not(:disabled):not(.disabled):active:focus {
      box-shadow: 0 0 0 0.2rem $shadow-black !important;
    }
  }

  &.btn-positive:not(:disabled):not(.disabled):active, &.btn-neutral:not(:disabled):not(.disabled):active,
  &.btn-negative:not(:disabled):not(.disabled):active, &.btn-negative-answer:not(:disabled):not(.disabled):active,
  &.btn-neutral-answer:not(:disabled):not(.disabled):active, &.btn-positive-answer:not(:disabled):not(.disabled):active {
    border: 1px solid transparent;
    color: $black;
    text-decoration: underline;
    border-radius: 5px;
  }

  &.question-actions-link {
    font-family: $secondary-font !important;
  }

  @include respond-above(sm) {
    min-width: 180px;
  }

  &:hover {
    text-decoration: underline;
  }

  &.disabled,
  &:disabled {
    text-decoration: none;
  }

  &.btn-link {
    color: $black;
    min-width: initial;
    padding: initial;

    &:hover {
      background-color: transparent;
    }

    &:not(:disabled):not(.disabled):active {
      background-color: $gray-118;
      border-color: $gray-118;
    }

    &.focus,
    &:focus,
    &:not(:disabled):not(.disabled):active:focus {
      box-shadow: 0 0 0 0.2rem $shadow-3;
    }

    &.disabled,
    &:disabled {
      color: $gray-136;
    }

    &.btn-sm {
      font-size: 1rem;
      line-height: 1.5;
    }

    &.primary-font-link {
      font-family: $primary-font;
      letter-spacing: inherit;
      font-size: inherit;
      vertical-align: inherit;
      text-decoration: underline;
      &:focus {
        outline: 0.2rem solid #000 !important;
        border-radius: inherit;
      }
      &:hover {
        text-decoration: none;
      }
    }
  }

  //Button - Primary Outline
  &.btn-outline-primary {
    border-color: $black;
    border-width: 2px;
    color: $black;

    &:hover {
      background-color: transparent;
    }

    &:not(:disabled):not(.disabled):active {
      background-color: $gray-118;
      border-color: $gray-118;
    }

    &.focus,
    &:focus,
    &:not(:disabled):not(.disabled):active:focus {
      box-shadow: 0 0 0 0.2rem $shadow-3;
    }

    &.disabled,
    &:disabled {
      border-color: $gray-136;
      color: $gray-136;
    }
  }

  &.btn-xl {
    width: 100%;
    white-space: nowrap;

    @include respond-above(md) {
      padding-right: 5rem;
      padding-left: 5rem;
      width: auto;
    }
  }
}


// ISAAC
.btn-underline {
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
}

a.btn {
  text-decoration: none;
}
.btn {
  &.btn-link {
    font-size: initial;
    font-weight: initial;
  }

  &.btn-sm {
    padding: 0.25rem 0.75rem;
    font-size: 1rem;
    min-width: 80px;
  }

  &.btn-tertiary {
    background: $gray-107;
    color: $black;
  }

  &.btn-outline-tertiary {
    border-color: $gray-118;
    border-width: 2px;
    color: $black;

    &:hover {
      background-color: transparent;
    }

    &:not(:disabled):not(.disabled):active {
      background-color: $gray-136;
      border-color: $gray-136;
    }

    &.focus,
    &:focus,
    &:not(:disabled):not(.disabled):active:focus {
      box-shadow: 0 0 0 0.2rem $shadow-08;
    }

    &.disabled,
    &:disabled {
      border-color: $gray-107;
      color: $gray-107;
    }
  }
}

.btn, input.btn {
  &.btn-negative {
    background-color: $phy_orangered;
  }
  &.btn-neutral {
    background-color: $phy_extra_force_yellow;
  }
  &.btn-positive {
    background-color: $a11y_green;
  }

  &.btn-secondary:not(:disabled):not(.disabled):active {
    background-color: darken($secondary, 4);
    border: 1px solid transparent;
    text-decoration: underline;
  }

  &.btn-outline-primary:not(:disabled):not(.disabled):active {
    color: black;
  }

  //Button - Secondary Outline
  &.btn-outline-secondary {
    background-color: $black;
    border-color: $black;
    border-width: 2px;
    color: $white;

    &:not(:disabled):not(.disabled):active {
      background-color: $gray-118;
      border-color: $gray-118;
    }

    &.focus,
    &:focus,
    &:not(:disabled):not(.disabled):active:focus {
      box-shadow: 0 0 0 0.2rem $shadow-3;
    }

    &.disabled,
    &:disabled {
      border-color: $gray-160;
      color: $gray-160;
    }
  }
}

.btn-green {
  @include button-variant($a11y_green, $a11y_green);
}

.quick-question-options {
  background: rgba($secondary, 0.5);
  border: 1px solid transparent;
  transition: opacity 0.2s linear;
}

.quick-question-muted {
  opacity: 0.6;
}

.hex-icon {
  width: 50px;
  height: 58px;
  background-repeat: no-repeat;
  z-index: 1;
  float: right;
  position: relative;
  margin-right: 5px;
  margin-left: 5px;
  background-color: transparent;
}

.share-link-icon {

  @extend .hex-icon;

  button {
    background-image: url(/assets/common/icons/share.svg);
    background-repeat: no-repeat;
    background-color: transparent;
    z-index: 2;
    width: 100%;
    height: 100%;
    position: relative;
  }

  .share-link {
    z-index: 1;
    font-family: $secondary-font !important;
    display: none;
    position: absolute;
    padding: 4px 32px 4px 8px;
    border: 1px solid gray;
    transform: translate(calc(25px - 100%), -65px);
    background: #fff;
    height: 58px;
    width: 70%;

    input {
      position: relative;
      top: 9px;
      white-space: nowrap;
      text-overflow: ellipsis;
      user-select: all;
      -webkit-user-select: all;
      -moz-user-select: all;
      -ms-user-select: all;
      width: 100%;
      padding-left: 2px;
      border-width: 1px;
    }

    &.double-height {
      height: 118px;
    }
  }
}

.btn-close { // bootstrap <Button close/> components
  width: 10px;
  height: 10px;
  min-width: 10px;
  min-height: 10px;
  background-clip: content-box;
  border-radius: 0;
}

button.close { // default <button class="close"/> components;
  background-color: transparent;
}

.btn-plus.btn-plus { // need the specificity to be above bootstrap's .btn
  @extend .btn-close;
  background-image: url(/assets/common/icons/plus.svg);
}

.print-icon {
  @extend .hex-icon;
  background-image: url(/assets/common/icons/print.svg);
}

.report-icon {
  @extend .hex-icon;
  background-image: url(/assets/common/icons/flag.svg);
}

// Button focus selectors

.btn.btn-link:focus {
  box-shadow: 0 0 0 0.2rem $focus-blue !important;
  outline: none !important;
  border-radius: 3px;
}

.btn-action:focus {
  box-shadow: 0 0 0 2pt black !important;
  border-radius: 2px;
  outline: none !important;
}

.btn.btn-outline-primary:focus {
  box-shadow: 0 0 0 2pt $secondary !important;
  border-radius: 8px;
  outline: none !important;
}

.btn-secondary:focus {
  box-shadow: 0 0 0 2pt black !important;
  border-radius: 8px;
  outline: none !important;
}

.btn-danger:focus {
  box-shadow: 0 0 0 2pt black !important;
  border-radius: 8px;
  outline: none !important;
}

.btn-outline-primary:focus {
  border-color: $secondary !important;
}

.btn.btn-sm.a-alt:focus {
  box-shadow: 0 0 0 0.2rem $focus-blue !important;
  outline: none !important;
  border-radius: 3px;
}

.bin-icon {
  background-image: url("/assets/phy/icons/bin-black.svg");
  width: 48px;
  height: 48px;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-color: transparent;
}

.separate-input-group {
  input {
    border-radius: 0.25rem;
  }
  button {
    border-radius: 0 0.25rem 0.25rem 0;
  }
}
