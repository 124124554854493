.subtitle {
  font-size: 1.25rem;
}

.menu-card:focus {
  border: none;
  box-shadow: none;
  border-radius: 0;
  outline: none;
  &:after {
    box-shadow: 0 0 0 2px black;
  }
}

.isaac-cards-body {
  .row {
    margin-bottom: 15px;
    display: flex;
    position: relative;

    &.teacher-features {
      display: block;
      width: 62.5%;
      margin-left: 18.75%;
    }
  }

  .col-centered{
    padding: 0 0 0 0;
    float: none;
    margin: 0 auto;
  }

  a.menu-card {
    width: 94%;
    margin: 10px 3%;
    text-decoration: none;
    display: inline-block;
    height: 90%;
    &.disabled{
      color: #ccc;
      &:hover .card-title {
        color: #ccc;
      }
    }
  }

  button.menu-card {
    width: 94%;
    padding: 0;
    margin: 10px 3%;
    text-decoration: none;
    display: inline-block;
    height: 90%;
    background-color: transparent;
    text-align: left;
  }

  .card-title {
    font-size: 1.4rem;
    font-weight: 600;
    font-family: exo_2regular, 'sans-serif';
    line-height: 1.8rem;
    padding-top: 10px;
  }

  .card-body {
    padding: 0;
  }

  .card {
    border-width: 1px;
    border-color: $phy_green;
    border-radius: 0;
    min-height: 170px;
    &.disabled {
      border-color: #ccc !important;
    }
    &:focus {
      border: none;
      box-shadow: 0 0 0 2px $phy_green !important;
      border-radius: 0;
      outline: none !important;
    }
    &:hover {
      box-shadow: 0 0 0 2px $phy_green !important;
      border-radius: 0;
      outline: none !important;
    }
  }

  button.hexagon-btn-link {
    color: $black;
    text-decoration: underline;
    min-width: initial;
    padding: initial;

    &:hover {
      text-decoration: none;
    }
  }

  a.hexagon, div.hexagon, button.hexagon {
    background-image:url(/assets/phy/icons/hexagon.svg#green);
    background-size: contain;
    background-repeat: no-repeat;
    color: black;
    width:220px;
    height:260px;
    overflow:hidden;
    display: block;
    margin-left: auto;
    margin-right: auto;
    background-color: transparent;

    &:focus {
      background-image:url(/assets/phy/icons/hexagon.svg#green-bold);
      outline: none;
    }

    &.disabled{
      color: #ccc;
      background-image:url(/assets/phy/icons/hexagon.svg#grey);
      &:hover .hexagon-title {
        color: #ccc;
      }
      &:focus {
        background-image:url(/assets/phy/icons/hexagon.svg#grey-bold);
      }
    }
  }

  a.hexagon, button.hexagon {
    &:hover .hexagon-title {
      color: $green;
    }
  }

  img.hexagon-btn {
    margin-top: -50px !important;
  }

  img.hexagon {
    display: block;
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;

    &.disabled {
      -webkit-filter: grayscale(100%);
      -webkit-filter: grayscale(1);
      filter: grayscale(100%);
      filter:gray;
      opacity: .5;
    }
  }

  img.menu-card {
    //float: left;
    width: 90px;
    height: 90px;
    display: block;
    margin-left: auto;
    margin-right: auto;

    &.isaac-card-vertical {
      float: none;
      width: 100%;
      height: unset;
      margin-right: auto !important;
      margin-left: auto !important;
      display: block;

      &.teacher-features {
        width: 150px;
      }
    }

    &.disabled {
      -webkit-filter: grayscale(100%);
      -webkit-filter: grayscale(1);
      filter: grayscale(100%);
      filter:gray;
      opacity: .5;
    }
  }

  div.hexagon-title {
    font-size: 1.6rem;
    font-weight: 600;
    font-family: exo_2regular, 'sans-serif';
    text-align: center;
    margin-top: 10px;
    margin-left: 20px;
    margin-right: 20px;
    line-height: 1.6rem;
    height: 85px;
  }

}

.hexagon-offset {
  @include respond-above(lg) {
    margin-left: -250px;
  }
}

.hexagon-offset-large {
  @include respond-above(lg) {
    margin-top: -55px;
  }
}
