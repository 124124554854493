@import "../common/filter";

#filter-panel {
  margin-bottom: 42px;
  #expand-filter-button {
    border-top-left-radius: unset;
    border-top-right-radius: unset;
    background-color: $secondary;
    -webkit-mask-image: url(/assets/common/icons/reveal-down.svg);
    mask-image: url(/assets/common/icons/reveal-down.svg);
    &.open {
      -webkit-mask-image: url(/assets/common/icons/reveal-up.svg);
      mask-image: url(/assets/common/icons/reveal-up.svg);
    }
    display: block;
    height: 34px;
    width: 40px;
    left: 50%;
    transform: translate(-50%, 0); // Centers the button correctly
    bottom: -34px;
    position: absolute;
    background-position: 50% top;
    background-repeat: no-repeat no-repeat;
    cursor: pointer;
    z-index: 0;
  }
  .filter-go-to-questions {
    color: $secondary;
    display: block;
    text-align: center;
    position: absolute;
    transform: translate(-50%, 0);
    bottom: 0;
    left: 50%;
    border: 1px solid $secondary;
    border-bottom: none;
    padding: 0.25rem 0.75rem;
    border-radius: 10px 10px 0 0;
    font-weight: bold;
  }
  .filter-action {
    text-decoration: underline;
    &:hover, &:focus {text-decoration: none;}
  }
}

$shapes: hex, square, octagon, diamond, circle;

svg {
  @each $shape in $shapes {
    .#{$shape} {
      &.active.physics {
        fill: $phy_physics;
        stroke: $phy_physics;
      }

      &.active.maths {
        fill: $phy_maths;
        stroke: $phy_maths;
      }

      &.active.chemistry {
        fill: $phy_chemistry;
        stroke: $phy_chemistry;
      }

      &.active.biology {
        fill: $phy_biology;
        stroke: $phy_biology;
      }

      &:not(.active) {
        fill: none;
        stroke: $gray-160;
      }

      &.mini:not(.active) {
        fill: white;
        stroke: none;
      }

      &.de-emph {
        stroke: $gray-120
      }

      &.none {
        fill: none;
        stroke: none;
      }

      &.clickable {
        cursor: pointer;
      }

      &.level.active {
        fill: $phy_green;
        stroke: $phy_green;
      }

      &.active.difficulty.practice {
        fill: $phy_green;
        stroke: $phy_green;
      }

      &.active.difficulty.challenge {
        fill: $phy_extra_force_yellow;
        stroke: $phy_extra_force_yellow;
      }

      &.filter-count {
        fill: $phy_green;
        opacity: .1;
        stroke: none;
      }

      &:focus {
        outline: none;
        stroke: black !important;
        stroke-width: 0.2rem;
      }
    }
  }
  .connection {
    fill: none;
    &.active.physics {
      stroke: $phy_physics;
    }
    &.active.maths {
      stroke: $phy_maths;
    }
    &.active.chemistry {
      stroke: $phy_chemistry;
    }
    &.active.biology {
      stroke: $phy_biology;
    }
    &:not(.active) {
      stroke: $gray-118;
    }
  }

}

/* Places to look for browser-specific media queries:
 - https://www.browserstack.com/guide/create-browser-specific-css
 - https://browserstrangeness.bitbucket.io/css_hacks.html#safari
*/
.hexagon-coming-soon {
  width: 100%;
  pointer-events: none;
  position: fixed;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 0.65rem;
  bottom: 0;
  left: 0;
  border-radius: 5px;
  font-family: $secondary-font-semi-bold;
  background: white;
  border-style: solid;
  border-width: 1px;

  &.physics {
    border-color: $phy_physics;
  }

  &.maths {
    border-color: $phy_maths;
  }

  &.chemistry {
    border-color: $phy_chemistry;
  }

  &.biology {
    border-color: $phy_biology;
  }
}
