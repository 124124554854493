@import "../common/books";

.book-levels {
  button {
    color: $phy_burgundy;
    &:hover {
      background-color: $phy_burgundy;
    }
    &:focus {
      background-color: $phy_burgundy;
    }

    /* Subjects can change the default colours: */
    .physics & {
      color: $phy_physics !important;
      &:hover {
        color: #fff !important;
        background-color: $phy_physics !important;
      }
      &:focus {
        color: #fff !important;
        background-color: $phy_physics !important;
      }
    }
    .chemistry & {
      color: $phy_chemistry;
      &:hover {
        color: #fff;
        background-color: $phy_chemistry;
      }
      &:focus {
        color: #fff;
        background-color: $phy_chemistry;
      }
    }
    .maths & {
      color: $phy_maths;
      &:hover {
        color: #fff;
        background-color: $phy_maths;
      }
      &:focus {
        color: #fff;
        background-color: $phy_maths;
      }
    }
    .biology & {
      color: $phy_biology;
      &:hover {
        color: #fff;
        background-color: $phy_biology;
      }
      &:focus {
        color: #fff;
        background-color: $phy_biology;
      }
    }
  }
}

svg.book-page-icon {
  polygon {
    fill: $phy_burgundy;
    .physics & {
      fill: $phy_physics;
    }
    .chemistry & {
      fill: $phy_chemistry;
    }
    .maths & {
      fill: $phy_maths;
    }
    .biology & {
      fill: $phy_biology;
    }
  }
}
