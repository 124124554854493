@import "../common/questions";

.question-actions {
  .question-actions-icon {
    height: 3rem;
    &:focus {
      outline: none;
    }
  }
  .question-actions-link-box {
    float:right;
    border: 1px solid gray;
    height: 3.6rem;
    border-right: none;
    padding-right: 2.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: -1.9rem;
    background: white;

    .question-actions-link {
      padding-left: 3px;
      padding-right: 3px;
      margin-left: 1rem;
      font-style: italic;
    }
  }
}

.question-panel > .content-chunk > .content-value,
.question-component .question-content {
  font-family: $primary-font;
}
.question-panel > .content-chunk > .content-value {
  background: none;
  box-shadow: none;
  padding: 0 0.25rem;
  font-weight: 400;
}

:not(.generic-panel):not(.concept-panel):not(.question-panel) > .content-chunk > .figure-panel {
  // if a figure is in the top level of a content block that is not the top level of a page (i.e. preamble), indent it. otherwise (i.e. when inside a layout component), leave it for that component to handle
  // TODO: move the padding from this to the layout component (usually accordions!)
  padding: 0 3rem;
}

.question-panel .isaac-accordion {
  background: $gray-103;
  .card-body > .content-chunk > .content-value {
    @extend .px-md-5;
    font-size: 1.25rem;
    line-height: 1.6;
  }
  // Make sure that reorder questions have a background when they expand on Phy
  .question-component:not(.expansion-layout) {
    background: none;
    box-shadow: none;
    padding: 0 !important;
    margin-bottom: 1.5rem;
    @include media-breakpoint-up(md) {
      padding-left: 3rem !important;
      padding-right: 3rem !important;
    }
  }
  // Make sure the banner does not overflow on reorder questions on Phy
  div:not(.expansion-layout) > .validation-response-panel {
    &.correct, &.almost {
      margin-left: -1rem;
      margin-right: -1rem;
      padding-left: 1.5rem !important;
      padding-right: 1.5rem !important;
      @include media-breakpoint-up(md) {
        margin-left: -4rem;
        margin-right: -4rem;
      }
    }
    &.correct {
      // correct (only) hides the "Check my answer" button, so does not need the bottom spacing
      margin-bottom: -2.75rem;
    }
  }

  form + form {
    .question-component {
      margin-top: 4rem;
    }
  }
}

.quick-question {
  button {
    border-radius: 0 !important;

    &.active {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
  .alert {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
}

.concept-content-container, .generic-content-container {
  .quick-question {
    .question-content, .content-value, .btn {
      font-size: 1rem !important;
    }
  }
}

.symbolic-question,
.equality-page {
  .eqn-editor-preview {
    font-size: 1.5rem;
    border: solid 1px $black;
  }
}
