@import "../common/boards";

.board-subject-hexagon-size {
  width: 63px;
  min-width: 63px;
  height: 74px;
}

// Subject hexagons:
.board-subject-hexagon-container {
  position: absolute;
  margin: auto;
  @extend .board-subject-hexagon-size;

  &.table-view {
    position: relative;
    top: -5px;
  }
}
.board-subject-hexagon {
  position: absolute;
  @extend .board-subject-hexagon-size;

  &.z0 {
    z-index: 5;
  }
  &.z1 {
    z-index: 4;
    top: 5px;
    left: -5px;
  }
  &.z2 {
    z-index: 3;
    top: 5px;
    left: 5px;
  }
  &.z3 {
    z-index: 2;
    top: -5px;
    left: 0;
  }
}
.board-percent-completed {
  width: 100%;
  font-size: 30px;
  line-height: 110%;
  font-family: $secondary-font-semi-bold;
  color: #fff;
  z-index: 10;
  position: absolute;
  top: 20px;
  left: 5px;
  text-align: center;
  &:after {
    font-size: 0.5em;
    content: '%';
    position: relative;
    top: -18px;
  }
}

.board-subject-hexagon {
  background: url(/assets/phy/icons/hexagons_sprite.png) no-repeat -256px 0;
  left: 0;
}
.subject-physics {
  background-position: 0 0;
}
.subject-maths {
  background-position: -64px 0;
}
.subject-chemistry {
  background-position: -128px 0;
}
.subject-biology {
  background-position: -192px 0;
}
.subject-complete {
  background-position: -320px 0;
}
