@import "../common/icons";

.icon-help {
  display: inline-block;
  width: 19px;
  min-width: 19px;
  height: 22px;
  background: url(/assets/phy/ru.png) -308px -324px no-repeat;
  margin-bottom: -4px;
  margin-left: 8px;
  margin-right: 8px;
}
.icon-person-active {
  float: left;
  width: 9px;
  min-width: 9px;
  height: 14px;
  background: url(/assets/phy/ru.png) -296px -302px no-repeat;
  margin: 3px 10px 0 0;
}
.icon-person {
  float: left;
  width: 9px;
  min-width: 9px;
  height: 14px;
  background: url(/assets/phy/ru.png) -296px -316px no-repeat;
  margin: 0 10px 0 0;
}
.icon-group {
  float: left;
  width: 23px;
  min-width: 23px;
  height: 19px;
  background: url(/assets/phy/ru.png) -330px -321px no-repeat;
  margin: 10px 10px 10px 0;
}
.icon-group-table-person {
  float: left;
  width: 11px;
  min-width: 11px;
  height: 18px;
  background: url(/assets/phy/ru.png) -357px -320px no-repeat;
  margin: 8px 10px 0 0;
}
.icon-status {
  width: 46;
  margin-top: -1px;
}

.icon-person-active,
.icon-person,
.icon-warning,
.icon-help,
.icon-group,
.icon-group-table-person
{
  background-image: url(/assets/phy/ru.png) !important;
}

svg {
  &.physics-fill {
    fill: $phy_purple;
  }

  &.maths-fill {
    fill: $phy_maths;
  }

  &.chemistry-fill {
    fill: $phy_chemistry;
  }

  &.biology-fill {
    fill: $phy_biology;
  }

  &.almost-fill {
    fill: $phy_extra_force_yellow;
  }

  &.correct-fill {
    fill: $phy_green;
  }
}
